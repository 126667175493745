import React from 'react';
import {LoginAcc,RecoverAcc,RegistAcc} from './AccountAction';

const Login = () => {
    return (
        <div>
            <LoginAcc/>
        </div>
    )
}

const Register = () => {
    return (
        <div>
            <RegistAcc/>
        </div>
    )
}

const Recover = () => {
    return (
        <div>
            <RecoverAcc/>
        </div>
    )
}

export {Login, Register, Recover}