import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineUser,
} from "react-icons/ai";
import { HiShoppingBag } from "react-icons/hi2";
import { FaUser, FaRegUser } from "react-icons/fa6";
import { GoHome } from "react-icons/go";
import { LuShirt } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import Cart from "../pages/cart";
import OpenCart from "../pages/cart/open-cart";
import { apiFunctions, apiFunctionsLP, urlAPILP } from "../data/api";
import { userInfo } from "../pages/Account/AuthProvider";
import { useNavigate } from "react-router-dom";

let searchTimeout;

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [query, setQuery] = useState({ search: "" });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const resultsContainerRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSearchToggle = () => {
    setShowSearchForm(!showSearchForm);
  };

  const handleSearch = (event) => {
    const newQuery = { ...query, search: event.target.value };
    setQuery(newQuery);
    setShowResults(true);
    setNoResults(false); // Reset no results flag on new search input

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      apiFunctions.productSearch(newQuery, fetchProducts);
    }, 500); // 500ms delay before making the API call
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const fetchProducts = (response) => {
    if (response && response.success && response.data) {
      const productsArray = Object.keys(response.data).map((key) => ({
        id: key,
        ...response.data[key],
      }));
      setResults(productsArray);
      setNoResults(productsArray.length === 0);
    } else {
      setResults([]);
      setNoResults(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query.search.length === 0) {
      setResults([]);
      setNoResults(false);
    }
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        resultsContainerRef.current &&
        !resultsContainerRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const Logout = () => {
    localStorage.removeItem("user-info");
    window.location.href = "/login";
  };

  const [contactVal, setContact] = useState([]);
  const [categoryVal, setCategory] = useState([]);
  const [menuVal, setMenu] = useState([]);

  useEffect(() => {
    apiFunctionsLP.contact((data) => {
      setContact(data.data);
    });
    apiFunctions.category({}, (data) => {
      setCategory(data);
    });
    apiFunctions.menu({}, (data) => {
      setMenu(data.data);
      console.log(data.data);
    });
  }, []);

  return (
    <div>
      <div className="mx-auto flex justify-between items-center px-2 md:px-4 py-0 bg-white fixed w-full z-20">
        {/* Left side */}
        <div className="flex items-center w-[108px] md:w-[168px] lg:hidden">
          <div onClick={() => setNav(!nav)} className="cursor-pointer">
            <AiOutlineMenu className="icon" />
          </div>
        </div>

        {/* Logo */}
        <div className="flex items-center px-2 w-[200px] sm:w-[400px] lg:w-auto">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl px-2 m-auto">
            <a href="/">
              <img
                src={urlAPILP + contactVal.logo_img}
                alt=""
                className="h-20"
              />
            </a>
          </h1>
        </div>
        {/* Navbar Menu */}
        <div
          className={`flex items-center w-[108px] rounded-full md:w-[168px] lg:w-auto !text-black navbar-menu`}
        >
          <div className="hidden lg:block">
            {menuVal.length > 0 &&
              menuVal.map((item) => (
                <a
                  href={item.url}
                  className="rounded px-1 lg:py-2 lg:px-4"
                  key={item.url}
                >
                  {item.name}
                </a>
              ))}
          </div>
          {userInfo ? (
            <>
              {showSearchForm && (
                <div className="absolute top-[70%] left-0 w-full bg-white/80 rounded-md shadow-lg z-10 mt-2 p-4 lg:w-1/4 lg:right-0 lg:left-auto">
                  <form onSubmit={handleSubmit} ref={resultsContainerRef}>
                    <input
                      type="text"
                      name="search"
                      value={query.search}
                      onChange={handleSearch}
                      placeholder="Search for products..."
                      className="p-2.5 px-3 w-full rounded bg-slate-100 text-sm border-0 hover:bg-slate-200 focus:outline-none focus:ring-1 focus:ring-[#ca8a04] focus:bg-white"
                      aria-label="Search Products"
                    />
                  </form>
                  {loading && (
                    <div className="text-xs text-gray-600 mt-2">Loading...</div>
                  )}
                  {showResults && !loading && results.length > 0 && (
                    <div
                      className="bg-white border rounded-lg rounded-t-none shadow-lg mt-0"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {results.map((product, index) => (
                        <div key={index} className="p-2 border-b text-sm">
                          <a
                            href={`/products/${product.item_group_id}`}
                            className="flex"
                          >
                            <p>{product.item_name}</p>
                            <img
                              alt={product.item_name}
                              src={product.thumbnail}
                              className="w-12 h-12 rounded ml-auto"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                  {showResults && !loading && noResults && (
                    <div
                      className="bg-white border rounded-lg rounded-t-none shadow-lg mt-0"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      <p className="p-2 px-3 text-[13px]">
                        Produk tidak ditemukan
                      </p>
                    </div>
                  )}
                </div>
              )}
              <p className="absolute bottom-2 md:bottom-1 md:right-12 lg:right-8 text-xs md:text-sm">
                Hai {userInfo.admin.name}
              </p>
              <button
                onClick={handleSearchToggle}
                className={`px-1 md:px-4 lg:p-2 border-0 rounded hover:bg-slate-200 ${
                  showSearchForm ? "bg-slate-200" : ""
                }`}
              >
                <AiOutlineSearch className="icon !text-black" />
              </button>
              <div
                className="block group relative px-1 md:px-4 lg:p-2"
                onClick={handleDropdownToggle}
              >
                <AiOutlineUser className="icon !text-black cursor-pointer" />
                <div
                  className={`absolute right-0 md:-right-10 top-11 mt-2 w-32 rounded-b-md shadow-lg bg-white z-20 ${
                    dropdownOpen ? "block " : "hidden"
                  } `}
                >
                  <a
                    href="/admin/public/dashboard"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Dashboard
                  </a>
                  <div
                    onClick={Logout}
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 hover:rounded-b-md hover:cursor-pointer"
                  >
                    Sign Out
                  </div>
                </div>
              </div>
              <Suspense fallback={<OpenCart />}>
                <Cart />
              </Suspense>
            </>
          ) : (
            <>
              {showSearchForm && (
                <div className="absolute top-[70%] left-0 w-full bg-white/80 rounded-md shadow-lg z-10 mt-2 p-4 lg:w-1/4 lg:right-0 lg:left-auto">
                  <form onSubmit={handleSubmit} ref={resultsContainerRef}>
                    <input
                      type="text"
                      name="search"
                      value={query.search}
                      onChange={handleSearch}
                      placeholder="Search for products..."
                      className="p-2.5 px-3 w-full rounded bg-slate-100 text-sm border-0 hover:bg-slate-200 focus:outline-none focus:ring-1 focus:ring-[#ca8a04] focus:bg-white"
                      aria-label="Search Products"
                    />
                  </form>
                  {loading && (
                    <div className="text-xs text-gray-600 mt-2">Loading...</div>
                  )}
                  {showResults && !loading && results.length > 0 && (
                    <div
                      className="bg-white border rounded-lg rounded-t-none shadow-lg mt-0"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {results.map((product, index) => (
                        <div key={index} className="p-2 border-b text-sm">
                          <a
                            href={`/products/${product.item_group_id}`}
                            className="flex"
                          >
                            <p>{product.item_name}</p>
                            <img
                              alt={product.item_name}
                              src={product.thumbnail}
                              className="w-12 h-12 rounded ml-auto"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                  {showResults && !loading && noResults && (
                    <div
                      className="bg-white border rounded-lg rounded-t-none shadow-lg mt-0"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      <p className="p-2 px-3 text-[13px]">
                        Produk tidak ditemukan
                      </p>
                    </div>
                  )}
                </div>
              )}
              <button
                onClick={handleSearchToggle}
                className={`px-1 md:px-4 lg:p-2 border-0 rounded hover:bg-slate-200 ${
                  showSearchForm ? "bg-slate-200" : ""
                }`}
              >
                <AiOutlineSearch className="icon !text-black" />
              </button>
              <a href="/login" className="px-1 md:px-4 lg:p-2">
                <AiOutlineUser className="icon !text-black" />
              </a>
              <Suspense fallback={<OpenCart />}>
                <Cart />
              </Suspense>
            </>
          )}
        </div>

        {/* Mobile Menu */}
        {/* Overlay */}
        {nav ? (
          <div
            className="bg-black/80 fixed w-full h-screen z-20 top-0 left-0"
            onClick={() => setNav(!nav)}
          >
            <AiOutlineClose
              onClick={() => setNav(!nav)}
              size={30}
              className="absolute right-4 top-4 cursor-pointer text-white"
            />
          </div>
        ) : (
          ""
        )}

        {/* Side drawer menu */}
        <div
          className={
            nav
              ? "fixed top-0 left-0 w-64 md:w-[300px] h-screen bg-white z-20 duration-300"
              : "fixed top-0 left-[-100%] w-64 md:w-[300px] h-screen bg-white duration-300"
          }
        >
          <h2 className="text-2xl p-4 pt-10">
            <div className="bg-gray-100 border rounded-full flex items-center px-2 w-full sm:w-full lg:w-full">
              <input
                className="bg-transparent p-2 w-full focus:outline-none text-sm"
                type="text"
                placeholder="Search..."
              />
              <AiOutlineSearch size={25} />
            </div>
          </h2>
          <nav>
            <ul className="flex flex-col text-gray-500 text-sm">
              <div className="block lg:hidden">
                {menuVal.length > 0 &&
                  menuVal.map((item) => (
                    <>
                      <a href={item.url}>
                        <li className="p-4 flex border-b-[1px]">{item.name}</li>
                      </a>
                    </>
                  ))}
              </div>
              <a href="https://batikorganik.co.id/partnership">
                <li className="p-4 flex border-b-[1px]">Partnership</li>
              </a>
              <a href="https://batikorganik.co.id/blog">
                <li className="p-4 flex border-b-[1px]">Blog</li>
              </a>
            </ul>
          </nav>
        </div>
      </div>
      <div className="bg-white flex h-14 fixed bottom-0 w-screen lg:hidden z-10 justify-between drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
        <a
          href="/"
          className="flex flex-col text-center items-center p-1 w-[75px] md:w-[100px]"
        >
          <div className="cursor-pointer m-auto">
            <GoHome className="icon" />
          </div>
          <h1 className="text-xs m-auto">Home</h1>
        </a>
        <a
          href="/products"
          className="flex flex-col text-center items-center p-1 w-[75px] md:w-[100px]"
        >
          <div className="cursor-pointer m-auto">
            <LuShirt className="icon" />
          </div>
          <h1 className="text-xs m-auto">Shop</h1>
        </a>
        <a
          href="/checkout"
          className="flex flex-col text-center items-center p-1 w-[75px] md:w-[100px]"
        >
          <div className="cursor-pointer m-auto">
            <MdOutlinePayment className="icon" />
          </div>
          <h1 className="text-xs m-auto">Confirm</h1>
        </a>
        <a
          href={userInfo ? "/admin/public/dashboard" : "/login"}
          className="flex flex-col text-center items-center p-1 w-[75px] md:w-[100px]"
        >
          <div className="cursor-pointer m-auto">
            <FaRegUser className="icon" />
          </div>
          <h1 className="text-xs m-auto">Account</h1>
        </a>
        <a
          href={contactVal.whatsapp}
          className="flex flex-col text-center items-center p-1 w-[75px] md:w-[100px]"
        >
          <div className="cursor-pointer m-auto">
            <FaWhatsapp className="icon" />
          </div>
          <h1 className="text-xs m-auto">Whatsapp</h1>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
