import React, { useEffect, createContext, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiry = () => {
      const userInfo = localStorage.getItem('user-info');
      if (userInfo) {
        const { expires_in } = JSON.parse(userInfo);
        const now = Date.now();
        if (now >= expires_in) {
          localStorage.removeItem('user-info');
          
        }
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);  // Check every minute
    return () => clearInterval(interval);  // Clear interval on component unmount
  }, [navigate]);

  return (
    <AuthContext.Provider value={{}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const userInfo = JSON.parse(localStorage.getItem('user-info'));

export const ProtectedRoute = ({ children }) => {

    if (!userInfo) {
      return <Navigate to="/login" />;
    }
  
    return children;
};
