import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'; 

import { apiFunctions, fetchSalesById, urlAPI } from '../../data/api.js';
import Accordion from '../../components/commons/Accordion';
import { formatPrice } from '../../data/function.js';
import { PaginationPage } from '../../components/commons/Pagination.jsx';

const Sale = () => {
  const [catVal, setCategory] = useState([]);
  const [salesVal, setSales] = useState([]);

  useEffect(()=>{
    apiFunctions.category({},(data) => {
        setCategory(data);
    });
    apiFunctions.sales({}, async (data) => {
        const salesData = data.data;
        
        const salesWithDetails = await Promise.all(salesData.map(async (sale) => {
          const saleDetails = await new Promise((resolve) => {
            fetchSalesById(sale.id, resolve);
          });
          return {
            ...sale,
            dataItem: saleDetails.data.items,
          };
        }));
        setSales(salesWithDetails);
    });
  }, [])

  const today = new Date().toISOString().split('T')[0];
  const validSales = salesVal.filter(sale => sale.end_date >= today);
  console.log(salesVal,'salesVal')
  console.log(validSales,'validSale')

  return (
    <div>
      {/* {collectVal.length >= 1 && */}
      <div className='py-24 md:py-32 border-b-[1px]'>
        <div className='max-w-[1240px] mx-auto md:pb-4 px-4 text-center'>
          <div className='alegreya-sans-light text-lg'>
            <h1 className='font-medium pb-4 text-2xl md:text-3xl uppercase'>Special Deals!</h1>
          </div>
        </div>
        <div>
          <div className='w-full relative md:flex h-auto flex-wrap justify-between px-6 pt-0 md:p-0'>
            {validSales.length > 0 ? (
                <div className='m-auto'>
                {validSales.map((sale, index) => (
                    <React.Fragment key={index}>
                    <div className='md:h-16 rounded-lg max-w-screen-xl my-auto mt-5 md:mt-4'>
                        <h1 className='h-full content-center text-xl md:text-2xl underline underline-offset-8 text-red-500 rounded'>
                        {sale.name}
                        </h1>
                    </div>
                    <div className='grid md:grid-cols-2 xl:grid-cols-4 gap-3 md:gap-5 mt-5 lg:mt-5'>
                        {sale.dataItem.map((products, productIndex) => (
                        <a
                            href={`/products/${products.item_group_id}`}
                            key={productIndex}
                            className='border md:w-[21rem] xl:w-[18rem] shadow-lg rounded-lg hover:scale-105 duration-300 mx-auto mb-5'
                        >
                            <img
                            src={products.thumbnail}
                            alt={products.item_name}
                            className='md:w-[21rem] xl:w-[18rem] h-96 xl:h-80 object-cover rounded-t-lg'
                            />
                            <div className='px-1 md:px-2 py-2'>
                            {/* Category information can be added here */}
                            <p className='font-medium text-sm'>{products.item_name}</p>
                            <div className='flex mt-2'>
                                <p className='font-medium text-sm price-tag text-red-500'>{formatPrice(products.discount_price)}</p>
                                <p className='font-medium text-xs price-tag text-gray-400 my-auto mx-1'>
                                <del>{formatPrice(products.sell_price)}</del>
                                </p>
                                <span className='text-xs text-red-500 my-auto bg-red-500/20 px-0.5 rounded'>
                                -{sale.discount}%
                                </span>
                            </div>
                            </div>
                        </a>
                        ))}
                    </div>
                    </React.Fragment>
                ))}
                </div>
            ) : (
                <div className='m-auto py-40 text-center'>
                Product sale will appear shortly.
                </div>
            )}

          </div>
        </div>
        
      </div>
      {/* }  */}
    </div>
  );
};

export default Sale;
