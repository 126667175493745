import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Homepage from './pages/home/Homepage';
import Products from './pages/product/Products';
import Product2 from './pages/product2';
import CheckoutPage from './pages/checkout/CheckoutPage';
import PaymentOrder from './pages/checkout/PaymentOrder';
import Layout from './components/layouts';
import AllCollectPage from './pages/category/AllCollectPage';
import CollectPage from './pages/category/CollectPage';
import { Login } from './pages/Account/Account';
import { AuthProvider } from './pages/Account/AuthProvider';
import Categories from './pages/category/Category';
import Sale from './pages/sale';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Routes without Layout */}
          <Route path="/payment-success" element={<PaymentOrder />} />
          <Route path="/payment-failed" element={<PaymentOrder />} />

          {/* Routes with Layout */}
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />}/>
            <Route path="/" element={<Homepage />} />
            <Route path="/categories/:id" element={<CollectPage />} />
            <Route path="/categories" element={<AllCollectPage />} />
            <Route path="/products/:id" element={<Product2 />} />
            <Route path="/products" element={<AllCollectPage />} />
            {/* <Route path="/products/:id" element={<Products />} /> */}
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/payment" element={<PaymentOrder />} />

            <Route path="/new" element={<AllCollectPage />} />
            <Route path="/voucher" element={<AllCollectPage />} />
            <Route path="/discount" element={<Sale />} />
            <Route path="/bundles" element={<AllCollectPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
