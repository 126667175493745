import axios from "axios";

const API_KEY_Ongkir = "3a692c1178794928eb49f91604b2a696";
// const urlAPI = 'http://127.0.0.1:8000'
const urlAPI = "https://batikorganik.id/admin/public";
const urlAPILP = "https://batikorganik.co.id/admin";

// const baseURL = 'http://127.0.0.1:8000/api'; // Base URL for API requests
const baseURL = "https://batikorganik.id/api"; // Base URL for API requests

// Function to make a GET request
const fetchData = async (endpoint, params = {}, callback) => {
  try {
    const url = `${baseURL}/${endpoint}`;
    const response =
      params && Object.keys(params).length > 0
        ? await axios.get(url, { params })
        : await axios.get(url);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
};

const searchData = async (endpoint, query, callback) => {
  try {
    const url = `${baseURL}/${endpoint}`;
    console.log(`Searching with URL: ${url}`);
    const response = await axios.get(url, {
      body: { search: query },
    });
    callback(response.data);
  } catch (error) {
    console.error("Error searching data:", error);
  }
};

const fetchDataLP = async (endpoint, callback) => {
  try {
    const response = await axios.get(
      `https://batikorganik.co.id/api/${endpoint}`
    );
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
};

const directFetchData = async (endpoint, params) => {
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to make a POST request
const postData = async (endpoint, data, headers = {}) => {
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return null; // Return null in case of error
  }
};

// Define endpoints for different API requests
const endpoints = {
  banners: "banners",
  category: "category",
  products: "products",
  contact: "contact",
  productId: "product/item/:id",
  productCat: "product/category/:id",
  sections: "sections",
  voucher: "vouchers/check",
  vouchers: "vouchers",
  menu: "menus",
  sales: "sales",
  salesId: "sales/:id",
  productSearch: "product/search",
};

const fetchDataById = async (endpointTemplate, id, params, callback) => {
  const endpoint = endpointTemplate.replace(":id", id);
  fetchData(endpoint, params, callback);
};

// Exported specific functions using the generic fetchDataById
export const fetchProductById = (id, callback) =>
  fetchDataById(endpoints.productId, id, {}, callback);
export const fetchSalesById = (id, callback) =>
  fetchDataById(endpoints.salesId, id, {}, callback);
export const fetchProductByCat = (id, params, callback) =>
  fetchDataById(endpoints.productCat, id, params, callback);
export const productSearch = (query, callback) =>
  searchData(endpoints.productSearch, query, callback);

// Generate functions dynamically for each endpoint
const apiFunctions = {};
for (const key in endpoints) {
  if (endpoints.hasOwnProperty(key)) {
    const endpoint = endpoints[key];
    apiFunctions[key] = (params, callback) =>
      fetchData(endpoint, params, callback);
  }
}

const apiFunctionsLP = {};
for (const key in endpoints) {
  if (endpoints.hasOwnProperty(key)) {
    const endpoint = endpoints[key];
    apiFunctionsLP[key] = (callback) => fetchDataLP(endpoint, callback);
  }
}

export const fetchProvinces = async () => {
  try {
    const response = await axios.get(`${baseURL}/ekpedisi/province`, {
      headers: {
        key: API_KEY_Ongkir,
      },
    });
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching provinces:", error);
    return [];
  }
};

export const fetchCities = async (provinceId) => {
  try {
    const response = await axios.get(
      `${baseURL}/ekpedisi/city?province=${provinceId}`,
      {
        headers: {
          key: API_KEY_Ongkir,
        },
      }
    );
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export const fetchSubdistricts = async (cityId) => {
  try {
    const response = await axios.get(
      `${baseURL}/ekpedisi/subdistrict?city=${cityId}`,
      {
        headers: {
          key: API_KEY_Ongkir,
        },
      }
    );
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export const fetchCourier = async () => {
  try {
    const response = await axios.get(`${baseURL}/ekpedisi/courier`, {
      headers: {
        key: API_KEY_Ongkir,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching courier:", error);
    return [];
  }
};

// Export API functions and baseURL
export {
  apiFunctions,
  apiFunctionsLP,
  urlAPI,
  urlAPILP,
  postData,
  directFetchData,
};
