import React, { useState, useEffect } from 'react';
import { HiOutlineShoppingBag } from 'react-icons/hi2';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../data/function';

const CartSidebar = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const updateCartItemsFromLocalStorage = () => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
          setCartItems(JSON.parse(storedCart));

          const totalQty = JSON.parse(storedCart).reduce((acc, item) => {
            return acc + (item.quantity);
          }, 0);
          setTotalQty(totalQty);

          const totalPrice = JSON.parse(storedCart).reduce((acc, item) => {
            return acc + (item.quantity * item.sell_price);
          }, 0);
          setTotalPayment(totalPrice);
        }
    };

    updateCartItemsFromLocalStorage();

    const handleCartUpdate = () => {
        updateCartItemsFromLocalStorage();
    };

    // Listen for cart update events
    window.addEventListener('cartUpdated', handleCartUpdate);

    return () => {
        window.removeEventListener('cartUpdated', handleCartUpdate);
    };
  }, []);

  const updateQuantity = (itemId, newQuantity) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.item_id === itemId) {
        if (newQuantity <= 0) {
          return null; 
        } else {
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    }).filter(Boolean); 

    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));

    const updatedTotalQty = updatedCartItems.reduce((acc, item) => acc + item.quantity, 0);
    setTotalQty(updatedTotalQty);

    const updatedTotalPayment = updatedCartItems.reduce((acc, item) => acc + (item.quantity * item.sell_price), 0);
    setTotalPayment(updatedTotalPayment);
  };


  const removeItem = itemId => {
    const updatedCartItems = cartItems.filter(item => item.item_id !== itemId);
    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };

  const proceedToCheckout = () => {
    // Implement your logic to proceed to checkout
    if(cartItems.length !== 0){
      navigate('/checkout');
      setIsOpen(false);
    }else{
      setIsOpen(false)
    }
  };

  return (
    <>
      <a className="px-1 md:px-4 lg:pl-2 lg:py-2">
        <HiOutlineShoppingBag className="icon !text-black" onClick={() => setIsOpen(true)} />
        <div className='absolute top-5 right-6 bg-black rounded-full w-5 h-5 text-[10px] text-white'>
          <span className='absolute top-0.5 right-0 left-0 text-center'>
            {totalQty}
          </span>
        </div>
      </a>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={() => setIsOpen(false)} />
      )}
      <div
        className={`fixed inset-y-0 right-0 w-80 md:w-96 bg-white shadow-lg p-4 transform transition-transform ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-black">My Cart</h2>
          <button onClick={() => setIsOpen(false)} className="text-black text-2xl p-0 border-0">
            &times;
          </button>
        </div>
        {cartItems.length === 0 ? (
          <p className="text-gray-500">Your cart is empty.</p>
        ) : (
          <ul className="divide-gray-200 border-t-[1px]">
            {cartItems.map(item => (
              <li key={item.item_id} className="py-2 flex items-center justify-between border-b-[1px]">
                <div className="flex items-center space-x-2">
                  <div className="h-14 w-14 md:h-16 md:w-16 relative overflow-hidden rounded-md">
                    <img src={item.thumbnail} alt="" className="object-cover w-full h-full" />
                  </div>
                  <div className='mb-auto'>
                    <p className="text-sm font-semibold w-36 md:w-40 text-yellow-600">{item.item_group_name}</p>
                    <p className="text-xs w-36 md:w-40">Variant: {item.variant}</p>
                  </div>
                </div>
                <div className='flex flex-wrap'>
                  <p className="text-black font-semibold text-sm m-auto">{formatPrice(item.sell_price)}</p>
                  <div className="flex items-center space-x-2 text-xl m-auto">
                    <button onClick={() => updateQuantity(item.item_id, item.quantity - 1)} 
                            className="text-black hover:text-yellow-600 py-0.5 px-0.5 md:px-2 border-0"
                            disabled={item.quantity <= 1}>
                      -
                    </button>
                    <p className="text-black text-sm">{item.quantity}</p>
                    <button onClick={() => updateQuantity(item.item_id, item.quantity + 1)} 
                            className="text-black hover:text-yellow-600 py-0.5 px-0.5 md:px-2 border-0"
                            disabled={item.quantity >= item.end_qty}>
                      +
                    </button>
                    <button onClick={() => removeItem(item.item_id)} className="text-gray-500 text-base hover:text-black px-0 relative bottom-0 border-0 right-0">
                      <MdDelete/>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className="fixed bottom-4 w-60 md:w-80 right-0 left-0 mx-auto">
          <div className='flex justify-between'>
            <p className="text-black font-semibold my-4">Total:</p>
            <p className='text-black font-semibold my-4'>{formatPrice(totalPayment)}</p>
          </div>
          <button
            onClick={proceedToCheckout}
            className="bg-yellow-600 text-white px-4 py-2 rounded-md hover:bg-yellow-700 w-full border-0"
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </>
  );
};

export default CartSidebar;
