import React, { useState, useEffect } from "react";
import Accordion from "../../components/commons/Accordion";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://batikorganik.id/api/category")
      .then(response => response.json())
      .then(data => {
        const nestedCategories = buildCategoryTree(data);
        setCategories(nestedCategories);
      })
      .catch(error => console.error("Error fetching categories:", error));
  }, []);

  const buildCategoryTree = (categories, parentId = 0) => {
    return categories
      .filter(category => category.parent_id === parentId)
      .map(category => ({
        ...category,
        children: buildCategoryTree(categories, category.category_id)
      }));
  };

  const renderCategories = (categories) => {
    return (
      <ul>
        {categories.map(category => (
          <li key={category.category_id}>
            {category.children.length > 0 ? (
              <Accordion
                title={category.category_name}
                answer={renderCategories(category.children)}
              />
            ) : (
              <a href={`/categories/${category.category_id}`}>{category.category_name}</a>
            //   <a href={`/category/${category.category_name.replace(/\s+/g, '-').toLowerCase()}`}>{category.category_name}</a>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="categories">
      <Accordion
        title="All Categories"
        answer={renderCategories(categories)}
      />
    </div>
  );
};

export default Categories;
