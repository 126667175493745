import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'; 

import { urlAPI, apiFunctions, fetchProductByCat } from '../../data/api.js';
import Accordion from '../../components/commons/Accordion';
import { formatPrice } from '../../data/function.js';
import { PaginationPage } from '../../components/commons/Pagination.jsx';
import Categories from './Category.jsx';

const Collection = () => {
  const { id } = useParams();
  // const [collectVal, setCollect] = useState([]);
  const [catVal, setCat] = useState([]);
  
  const [collectVal, setCollect] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState(0);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);
  const fetchProducts = (page) => {
    fetchProductByCat(id, { page, limit: productsPerPage }, (data) => {
      setCollect(data.data);
      setProductsPerPage(data.page_size);
      setTotalProducts(data.total_count); // assuming the API returns total number of products
    });
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(()=>{
    apiFunctions.category({},(data) => {
      setCat(data);
    });
  }, [])

  const filteredCategory = catVal.filter(item => item.category_id === parseInt(id));

  return (
    <div>
      {collectVal.length >= 1 &&
      <div className='pb-14 border-b-[1px]'>
        <div className='max-w-[1240px] mx-auto pt-24 pb-4 px-4 text-center'>
          {filteredCategory && (
          <div className='alegreya-sans-light text-lg'>
            <h1 className='font-medium pb-4 text-2xl text-yellow-600 underline underline-offset-8 decoration-yellow-700 uppercase'>{filteredCategory.map((item)=>(item.category_name))}</h1>
            {/* <div className='md:max-w-[45rem] m-auto' dangerouslySetInnerHTML={{ __html: filteredCategory.description}} /> */}
          </div>
          )}
        </div>
        <div>
          <div className='w-full relative md:flex h-auto flex-wrap justify-between p-6 gap-5'>
            <div className='category-btn'>
              <Categories/>
            </div>
            {collectVal.length !== 0 ?
            <div className='lg:w-10/12 grid md:grid-cols-2 xl:grid-cols-4 gap-5 m-auto mt-5 lg:mt-0'>
                {collectVal.map((products, index) => (
                  <a href={`/products/${products.item_group_id}`}
                    key={index}
                    className='border w-auto shadow-lg rounded-lg hover:scale-105 duration-300 mx-auto mb-5 md:mb-10'
                    >
                    <img
                      src={products.thumbnail}
                      alt={products.item_name}
                      className='md:w-auto h-80 lg:h-72 object-cover rounded-t-lg'
                      />
                      <div className='px-1 md:px-2 py-2'>
                      {catVal.map((categoryItem,categoryIndex) => {
                        if (categoryItem.category_id === products.item_category_id) {
                          return (
                            <div key={categoryIndex}>
                              <span className='uppercase text-xs text-blue-900'>{categoryItem.category_name}</span>
                            </div>
                          );
                        }
                        return null;
                      })}
                        <p className='font-medium text-sm'>{products.item_name}</p>
                        <p className='font-medium text-sm price-tag text-red-500'>{formatPrice(products.sell_price)}</p>
                      </div>
                  </a>
                ))}
            </div>
            : 
            <div className='m-auto mt-3 text-center'>
              The product doesn't exist yet
            </div> 
            }
          </div>
          <PaginationPage
            currentPage={currentPage}
            totalProducts={totalProducts}
            productsPerPage={productsPerPage}
            onPageChange={handlePageChange}
          />
        </div>
        
      </div>
      }
    </div>
  );
};

export default Collection;
