import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const setMetaDescription = (description) => {
  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    document.head.appendChild(metaDescription);
  }
  metaDescription.content = description;
};

const setFavicon = (faviconUrl) => {
  let link = document.querySelector('link[rel="icon"]');
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = faviconUrl;
};

const customTitle = async () => {
  try {
    const response = await fetch('https://batikorganik.id/api/displays');
    const result = await response.json();

    if (result.status === 200) {
      const { main_title, main_favicon, main_headline } = result.data;
      if (main_title) {
        document.title = main_title;
      }
      if (main_favicon) {
        setFavicon(main_favicon);
      }
      if (main_headline) {
        setMetaDescription(main_headline);
      }
    } else {
      console.error('Failed to fetch display data:', result.message);
    }
  } catch (error) {
    console.error('Error fetching display data:', error);
  }
};

customTitle().then(() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
