import React from 'react'
import Batik from './Batik'

function Homepage() {
  return (
    <div>
        <Batik />
    </div>
  );
}

export default Homepage;
