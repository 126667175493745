import React from "react";
import { Outlet } from "react-router-dom"; // Import Outlet from react-router-dom
import Navbar from "../Navbar";
import Footer from "../Footer";
import FloatingActionButtons from "../BtnFloat";

const Layout = () => {
  return (
    <div className="w-full h-full">
      <Navbar />
      <main>
        <Outlet /> {/* This will render nested routes */}
      </main>
      <FloatingActionButtons />
      <Footer />
    </div>
  );
};

export default Layout;
