import React, { useEffect, useState } from 'react';
import CartModal from './modal';

export default function Cart() {
  const [cart, setCart] = useState(null);

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setCart(cartItems);
    } else {
      setCart([]);
    }
  }, []);

  return <CartModal cart={cart} />;
}
