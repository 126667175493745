import React, { useState } from 'react';
import { formatPrice } from '../../data/function.js';
import { CTASection } from './CTASection.jsx';

export const DetailsSection = ({ product, selectedVariant, onVariantChange, categoryName }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const description = product.description || '';  // Ensure description is defined
  const shortDescription = description.slice(0, 150);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const handleVariantClick = (variant) => {
    onVariantChange(variant);
  };

  return (
    <div className='flex flex-col p-6 pt-0 lg:pt-6 gap-3 md:w-full md:max-w-[600px]'>
      <div>
        {/* Subtitle */}
        <h2 className="uppercase text-yellow-600 mb-3 font-bold tracking-[0.13em] text-xs md:text-base">{categoryName}</h2>
        {/* Product Name */}
        <h1 className="font-bold text-3xl mb-4 md:text-5xl md:mb-5">{product.item_group_name}</h1>
        
        {/* Variant Selection */}
        {product.product_skus && product.product_skus.length > 0 && (
            <div className='mb-4'>
                <label htmlFor="variant" className="block mb-2 font-medium text-gray-700">Choose a variant:</label>
                <ul className="list-filter size-filter flex gap-2 flex-wrap text-sm">
                {/* {product.product_skus.filter(item => item.variation_values[0].label === "Warna").map(variant => { */}
                {product.product_skus.map(variant => {
                    const isDisabled = variant.end_qty <= 0;
                    return (
                    <li
                        key={variant.item_id}
                        className={`w-auto ${selectedVariant && selectedVariant.item_id === variant.item_id ? (isDisabled ? 'disabled' : 'active active-hover') : ''} `}
                        aria-disabled={isDisabled}
                    >
                        <a
                        onClick={() => !isDisabled && handleVariantClick(variant)}
                        className={`flex cursor-pointer ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                        >
                        <img src={variant.images.length > 0 && variant.images[0].cloud_key} alt="" className="my-auto h-[30px] mr-2"/>
                        <span>{variant.variation_values[0].value}</span>
                        </a>
                    </li>
                    );
                })}
                </ul>
            </div>
        )}

        {/* Product Price */}
        <div className='flex items-center justify-between md:flex-col md:items-start mb-4 md:mb-5'>
          <div className='flex gap-4 items-center'>
            {/* Price */}
            {selectedVariant.discounted_price ?
              <span className='font-bold text-2xl text-yellow-600'>{selectedVariant ? formatPrice(selectedVariant.discounted_price) : formatPrice(0)}</span>
              :
              <span className='font-bold text-2xl text-yellow-600'>{selectedVariant ? formatPrice(selectedVariant.sell_price) : formatPrice(0)}</span>
            }
            {/* Discount */}
            {selectedVariant.discounted_price &&
              <span className='bg-yellow-50 text-yellow-600 font-bold text-sm px-2 rounded-md'>{(selectedVariant.sell_price-selectedVariant.discounted_price)/selectedVariant.sell_price*100}%</span>
            }
          </div>
          {/* Previous price */}
          {selectedVariant.discounted_price &&
            <span className='text-grayish-blue text-sm font-bold'><del>{formatPrice(selectedVariant.sell_price)}</del></span>
          }
        </div>
        {/* Product Description */}
        <div className="text-dark-grayish-blue mb-5 text-sm leading-[22px] md:text-base">
          {isExpanded ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: shortDescription + '...' }} />
          )}
          <button onClick={toggleReadMore} className="rounded mt-2 border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white">
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </div>
      </div>
      <div className=''>
        <span className='bg-yellow-600 text-white text-xs px-2 py-1 rounded-md'>Stock: {selectedVariant && selectedVariant.end_qty > 0 ? selectedVariant.end_qty : 0}</span>
      </div>
      <CTASection ProductsVal={product} selectedVariant={selectedVariant}/>
    </div>
  );
};
