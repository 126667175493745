import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { apiFunctions, urlAPI } from "../../data/api.js";
import Accordion from "../../components/commons/Accordion";
import { formatPrice } from "../../data/function.js";
import { PaginationPage } from "../../components/commons/Pagination.jsx";
import Categories from "./Category.jsx";

const AllCollection = () => {
  const [catVal, setCategory] = useState([]);
  const [voucher, setVoucher] = useState([]);

  const location = useLocation();
  const pathTitleMap = {
    "/categories": "All Categories",
    "/products": "All Products",
    "/bundles": "All Bundles",
    "/voucher": "All Vouchers",
    "/new": "New Products",
  };

  const getTitle = (pathname) => pathTitleMap[pathname] || "All Products";

  // search Param
  const { search, state } = location;
  const searchResult = state?.searchResult;
  // console.log("Search Result:", searchResult);
  // console.log("Search Term:", new URLSearchParams(search).get("search"));

  // const [products, setProducts] = useState([]);
  const [collectVal, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState(0);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);
  const fetchProducts = (page) => {
    apiFunctions.products({ page, limit: productsPerPage }, (data) => {
      setProductsPerPage(data.page_size);
      setProducts(data.data);
      console.log("data", data.page_size);
      setTotalProducts(data.total_count);
    });
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    apiFunctions.category({}, (data) => {
      setCategory(data);
    });
    apiFunctions.vouchers({}, (data) => {
      setVoucher(data.data);
    });
  }, []);

  const handleCopyCode = (e, code) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(code)
      .then(() => {
        alert("Voucher code copied to clipboard: " + code);
        // Proceed with navigation after copying
        // window.location.href = e.target.href;
      })
      .catch((err) => {
        console.error("Failed to copy the code: ", err);
      });
  };

  return (
    <div>
      {/* {collectVal.length >= 1 && */}
      <div className="py-24 md:py-32 border-b-[1px]">
        <div className="max-w-[1240px] mx-auto pb-4 px-4 text-center">
          <div className="alegreya-sans-light text-lg">
            <h1 className="font-medium pb-4 text-2xl md:text-3xl uppercase">
              {getTitle(location.pathname)}
            </h1>
          </div>
        </div>
        <div>
          <div className="w-full relative md:flex h-auto flex-wrap justify-between p-6 pt-0 md:p-6">
            {location.pathname === "/voucher" ? (
              <div className="lg:w-10/12 grid md:grid-cols-2 xl:grid-cols-4 gap-5 xl:gap-24 m-auto mt-5 lg:mt-0">
                {voucher.map((voucher, index) => (
                  <a
                    onClick={(e) => handleCopyCode(e, voucher.code)}
                    key={index}
                    className="border md:w-[21rem] xl:w-[18rem] shadow-lg rounded-lg hover:scale-105 duration-300 mx-auto mb-5 md:mb-10"
                  >
                    <img
                      src={urlAPI + voucher.image_url}
                      alt={voucher.name}
                      className="md:w-[21rem] xl:w-[18rem] h-96 xl:h-80 object-cover rounded-t-lg"
                    />
                    <div className="px-1 md:px-2 py-2">
                      <p className="font-medium text-sm">{voucher.name}</p>
                      <p className="font-medium text-sm text-yellow-600">
                        {voucher.code}
                      </p>
                      <div
                        className="text-xs text-gray-400"
                        dangerouslySetInnerHTML={{
                          __html: voucher.description,
                        }}
                      />
                      {voucher.minimum_payment !== "0" && (
                        <div className="text-xs text-gray-400">
                          Min. Order: {formatPrice(voucher.minimum_payment)}
                        </div>
                      )}
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <>
                <div className="category-btn">
                  <Categories />
                </div>  

                {collectVal.length > 0 ? (
                  <div className="lg:w-10/12 grid md:grid-cols-2 lg:grid-cols-4 gap-5 m-auto mt-5 lg:mt-0">
                    {collectVal.map((products, index) => (
                      <a
                        href={`/products/${products.item_group_id}`}
                        key={index}
                        className="border md:w-[21rem] xl:w-[18rem] shadow-lg rounded-lg hover:scale-105 duration-300 mx-auto mb-5 md:mb-10"
                      >
                        <img
                          src={products.thumbnail}
                          alt={products.item_name}
                          className="md:w-[21rem] xl:w-[18rem] h-96 xl:h-80 object-cover rounded-t-lg"
                        />
                        <div className="px-1 md:px-2 py-2">
                          {catVal.map((categoryItem, categoryIndex) => {
                            if (
                              categoryItem.category_id ===
                              products.item_category_id
                            ) {
                              return (
                                <div key={categoryIndex}>
                                  <span className="uppercase text-xs text-blue-900">
                                    {categoryItem.category_name}
                                  </span>
                                </div>
                              );
                            }
                            return null;
                          })}
                          <p className="font-medium text-sm">
                            {products.item_name}
                          </p>
                          <p className="font-medium text-sm price-tag text-red-500">
                            {formatPrice(products.sell_price)}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="w-full m-auto py-40 text-center">
                    Please wait, the product will appear shortly.
                  </div>
                )}
              </>
            )}
          </div>
          {location.pathname !== "/voucher" && (
            <PaginationPage
              currentPage={currentPage}
              totalProducts={totalProducts}
              productsPerPage={productsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
      {/* }  */}
    </div>
  );
};

export default AllCollection;
