import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

export const PaginationPage = ({ currentPage, totalProducts, productsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const maxPagesToShow = window.innerWidth < 768 ? 3 : 5;

  const getPageNumbers = () => {
    const pageNumbers = [];
    const half = Math.floor(maxPagesToShow / 2);

    // Calculate start and end page numbers
    let startPage = Math.max(1, currentPage - half);
    let endPage = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + half >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
      endPage = totalPages;
    }

    // Add first page and ellipsis if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('..');
      }
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('..');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  
  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className='flex justify-center mt-4 text-xs md:text-base'>
      {currentPage > 1 && (
        <button
          onClick={handlePreviousPage}
          className='p-2 md:p-3 mx-1 border-0 rounded-full text-[#7E7E7E] bg-[#F2F3F4] hover:text-white hover:bg-yellow-600'
        >
          <FaArrowLeft/>
        </button>
      )}
      {getPageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => page !== '..' && onPageChange(page)}
          className={`px-3 md:px-4 py-1 mx-1 border-0 rounded-full font-medium hover:text-white hover:bg-yellow-600 ${page === currentPage ? 'text-white bg-yellow-600' : 'text-[#7E7E7E] bg-[#F2F3F4]'}`}
          disabled={page === '..'}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages && (
        <button
          onClick={handleNextPage}
          className='p-2 md:p-3 mx-1 border-0 rounded-full text-[#7E7E7E] bg-[#F2F3F4] hover:text-white hover:bg-yellow-600'
        >
          <FaArrowRight/>
        </button>
      )}
    </div>
  );
};
