import React, { useEffect, useState } from 'react';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok, FaWhatsapp
} from 'react-icons/fa';
import { apiFunctionsLP,urlAPILP } from '../data/api';

const Footer = () => {
  const [time, setTime] = useState(new Date());
  const [contactVal, setContact] = useState([]);

  useEffect(()=>{
    apiFunctionsLP.contact((data) => {
      // console.log(data);
      setContact(data.data);
    });
    const intervalId = setInterval(() => {
      setTime(new Date()); // Update current time every second
    }, 1000);
  }, [])

  return (
      
    <div>
      <div className='w-full mx-auto flex flex-col p-4 md:p-14 border-t-[1px]'>
        <div className='flex text-center justify-between items-center'>
          <div className='justify-between w-full sm:max-w-[280px] my-4 md:block hidden'>
            <img src={urlAPILP+contactVal.logo_img} alt="" className='h-40 object-cover mx-auto'/>
          </div>
          <ul className='flex justify-between gap-y-10 md:gap-24 flex-wrap'>
            <li className='text-justify'>
              <p className='text-base md:text-lg alegreya-sans-regular'>Account</p>
              <div className='text-sm pt-2 alegreya-sans-light'>
                <p className='pt-4'><a href="/admin/public/dashboard">Dashboard</a></p>
                <p className='pt-4'><a href="/admin/public/dashboard/orders">Order History</a></p>
                {/* <p className='pt-4'><a href="/reseller">Reseller Registration</a></p> */}
              </div>
            </li>
            <li className='text-justify'>
              <p className='text-base md:text-lg alegreya-sans-regular'>Information</p>
              <div className='text-sm pt-2 alegreya-sans-light'>
                {/* <p className='pt-4'><a href="/payment-confirm">Payment Confirmation</a></p> */}
                <p className='pt-4'><a href="https://batikorganik.co.id/partnership">Partnership</a></p>
                {/* <p className='pt-4'><a href="/size-chart">Size Chart</a></p>
                <p className='pt-4'><a href="/privacy-policy">Privacy Policy</a></p> */}
              </div>
            </li>
            <li className='text-justify'>
              <p className='text-base md:text-lg alegreya-sans-regular'>Contact</p>
              <div className='text-sm pt-2 alegreya-sans-light'>
                <p className='pt-4'><a href={`mailto:${contactVal.email}`}>{contactVal.email}</a></p>
                <p className='pt-4'><a href={contactVal.whatsapp}>{contactVal.phone}</a></p>
                <div className='flex w-full md:justify-normal gap-5 md:w-auto sm:max-w-[280px] my-4'>
                  {contactVal.instagram !== "0" && (
                    <a href={contactVal.instagram}><FaInstagram className='icon' /></a>
                  )}
                  {contactVal.tiktok !== "0" && (
                    <a href={contactVal.tiktok}><FaTiktok className='icon' /></a>
                  )}
                  {contactVal.twitter !== "0" && (
                    <a href={contactVal.twitter}><FaTwitter className='icon' /></a>
                  )}
                  {contactVal.facebook !== "0" && (
                    <a href={contactVal.facebook}><FaFacebook className='icon' /></a>
                  )}
                  {contactVal.whatsapp !== "0" && (
                    <a href={contactVal.whatsapp}><FaWhatsapp className='icon' /></a>
                  )}
                </div>
              </div>
            </li>
          </ul>
          
        </div>
      </div>
      <div class="flex-1 h-0.5 bg-gray-100"></div>
      <div className='w-full mx-auto p-8 pb-20'>
        <div className='text-center justify-between items-center'>
          <p className='text-xs md:text-sm'>{time.getFullYear()} &copy;  BatikOrganik</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
