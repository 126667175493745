import React, { useEffect, useState } from 'react';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';
import {Swiper, SwiperSlide} from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import {EffectCoverflow, Pagination, Navigation} from 'swiper';

import slide_image_1 from '../assets/batiklayout1.jpg'
import slide_image_2 from '../assets/batiklayout2.jpg'
import slide_image_3 from '../assets/batiklayout3.jpg'
import slide_image_4 from '../assets/batiklayout4.jpg'
import { fetchProductByCat } from '../data/api';
import { formatPrice } from '../data/function';

const Swiper1 = () => {

  return (
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}

        className='swiper_container'
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
  );
};

const Swiper1_md = () => {

  return (
    <div className='flex'>
      <img src={slide_image_1} alt="" className='pr-1 w-60 h-80'/>
      <img src={slide_image_2} alt="" className='pr-1 w-60 h-80'/>
      <img src={slide_image_3} alt="" className='pr-1 w-60 h-80'/>
    </div>
  );
};

const Swiper2 = () => {

  return (
      <Swiper
        grabCursor={true}
        centeredSlides={false}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[Pagination, Navigation]}

        className='swiper_container w-full'
      >
        <SwiperSlide className='p-1'>
          <div>
            <img src={slide_image_1} alt="slide_image" />
            <span className='uppercase text-xs'>Batik Series</span>
            <p className='font-medium text-sm'>Batik Series</p>
            <p className='font-medium text-sm'>Rp100.000</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className='p-1'>
          <div>
            <img src={slide_image_2} alt="slide_image" />
            <span className='uppercase text-xs'>Batik Series</span>
            <p className='font-medium text-sm'>Batik Series</p>
            <p className='font-medium text-sm'>Rp100.000</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className='p-1'>
          <div>
            <img src={slide_image_3} alt="slide_image" />
            <span className='uppercase text-xs'>Batik Series</span>
            <p className='font-medium text-sm'>Batik Series</p>
            <p className='font-medium text-sm'>Rp100.000</p>
          </div>
        </SwiperSlide>
        <SwiperSlide className='p-1'>
          <div>
            <img src={slide_image_4} alt="slide_image" />
            <span className='uppercase text-xs'>Batik Series</span>
            <p className='font-medium text-sm'>Batik Series</p>
            <p className='font-medium text-sm'>Rp100.000</p>
          </div>
        </SwiperSlide>
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
        </div>
      </Swiper>
  );
};

export const RelateProduct = ({ category_id, CategoryVal }) => {
  const [item, setItem] = useState([]);

  useEffect(() => {
    fetchProductByCat(category_id, {}, (data) => {
      setItem(data.data);
    });
  }, [category_id]);

  return (
      <Swiper
        grabCursor={true}
        loop={true}
        slidesPerView={'auto'}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className='swiper_container w-full swiper-product swiperLP'
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        {item.length > 0 && item.map((product, index) => (
          <SwiperSlide key={index} className='p-0.5 md:p-2'>
            <a href={`/products/${product.item_group_id}`}>
              <div className='hover:scale-[1.025] duration-300 border rounded-lg'>
                <img src={product.thumbnail} className='w-full md:h-96 rounded-t-lg' alt={`slide_image_${index}`} />
                <div className='px-1 md:px-2 py-2'>
                  <div>
                    <span className='uppercase text-xs text-blue-900'>{CategoryVal}</span>
                  </div>
                  <p className='font-medium text-xs md:text-sm'>{product.item_name}</p>
                  <p className='font-medium text-xs md:text-sm price-tag text-red-500'>{formatPrice(product.sell_price)}</p>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
        </div>
      </Swiper>
  );
};

export {Swiper1, Swiper1_md, Swiper2};
