import React, { useState, useEffect } from 'react';
import { data } from '../../data/data.js';
import { Swiper2 } from '../../components/Swiper.jsx';
import { urlAPI, apiFunctions, fetchProductByCat } from '../../data/api.js';

import {Swiper, SwiperSlide} from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { formatPrice } from '../../data/function.js';
import { PaginationPage } from '../../components/commons/Pagination.jsx';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const Batik = () => {
  const [SectionVal, setSection] = useState([]);
  const [CategoryVal, setCategory] = useState([]);

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  // const [productsPerPage, setProductsPerPage] = 12;
  const productsPerPage = 12;

  useEffect(() => {
    const fetchProducts = (page) => {
      apiFunctions.products({ page, limit: productsPerPage }, (data) => {
        setProducts(data.data);
        setTotalProducts(data.total_count); // assuming the API returns total number of products
      });
    };
    fetchProducts(currentPage);
  }, [currentPage]);

  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(()=>{
    apiFunctions.sections({},(data) => {
        setSection(data.data);
    });
    apiFunctions.category({},(data) => {
      setCategory(data);
    });
  }, [])

  return (
    <div className='max-w-[1640px] m-auto px-4 py-12'>
      {SectionVal && SectionVal.map(item =>
      <>
      <div className='py-1 md:py-4'>
        <div className='max-w-[1640px] mx-auto md:p-4 pt-8'>
          <div className='h-full relative'>
              <img className='w-full h-full object-cover rounded-lg' src={urlAPI+item.image_url} alt="/" />
          </div>
        </div>

        <div className='max-w-[1240px] mx-auto py-4 md:py-8 px-4 text-center'>
          <p className='text-sm'>{item.subtitle}</p>
          <h1 className='text-3xl md:text-5xl font-bold pb-5'>{item.title}</h1>
          <div className='p-5 pt-0 text-sm'>
            <div className='max-w-lg m-auto' dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
          <a href={item.button_link+(item.show_category === true ? item.category_id :'')}>
            <button className='rounded-full bg-yellow-600 border-0 shadow-[0_10px_20px_-12px] shadow-yellow-600 text-white px-4 py-2 uppercase hover:scale-105 duration-300'>{item.button_text}</button>
          </a>
        </div>
        {item.show_category === true && (
            <ProductCarousel category_id={item.category_id} CategoryVal={item.category_name} />
        )}
      </div>
      </>
      )}

      <div class="max-w-[1240px] flex items-center mx-auto pt-5 md:pt-20">
        <div class="flex-1 h-0.5 bg-gray-300"></div>
        <div class="uppercase mx-4 text-gray-600 font-semibold md:text-2xl">Latest Collection</div>
        <div class="flex-1 h-0.5 bg-gray-300"></div>
      </div>
      
      {/* Display product */}
      <div className='container grid md:flex md:flex-wrap justify-center grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-10 md:max-w-[1060px] lg:max-w-full m-auto'>
        
        {products.length > 0 && products.map((product, index) => (
          <a
            href={`/products/${product.item_group_id}`}
            key={index}
            className='border w-40 md:w-[21rem] lg:w-[20rem] xl:w-[21rem] shadow-lg rounded-lg hover:scale-105 duration-300 mx-[5px] mb-5 md:mb-10'
          >
            <img
              src={product.thumbnail}
              alt={product.item_name}
              className='w-40 md:w-[21rem] lg:w-[20rem] xl:w-[21rem] h-44 md:h-96 object-cover rounded-t-lg'
            />
            <div className='px-1 md:px-2 py-2'>
              {CategoryVal.map((categoryItem, categoryIndex) => {
                if (categoryItem.category_id === product.item_category_id) {
                  return (
                    <div key={categoryIndex}>
                      <span className='uppercase text-xs text-blue-900'>{categoryItem.category_name}</span>
                    </div>
                  );
                }
                return null;
              })}
              <p className='font-medium text-sm'>{product.item_name}</p>
              <p className='font-medium text-sm price-tag text-red-500'>{formatPrice(product.sell_price)}</p>
            </div>
          </a>
        ))}
      </div>
      <PaginationPage
        currentPage={currentPage}
        totalProducts={totalProducts}
        productsPerPage={productsPerPage}
        onPageChange={handlePageChange}
      />

    </div>
  );
};

const ProductCarousel = ({ category_id, CategoryVal }) => {
  const [item, setItem] = useState([]);

  useEffect(() => {
    fetchProductByCat(category_id, {}, (data) => {
      setItem(data.data);
    });
  }, [category_id]);

  return (
    <div className='container md:max-w-[1040px] m-auto'>
      <Swiper
        grabCursor={true}
        loop={true}
        slidesPerView={'auto'}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className='swiper_container w-full swiper-product swiperLP'
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        {item.length > 0 && item.map((product, index) => (
          <SwiperSlide key={index} className='p-2'>
            <a href={`/products/${product.item_group_id}`}>
              <div className='hover:scale-[1.025] duration-300 border rounded-lg'>
                <img src={product.thumbnail} className='w-full h-96 rounded-t-lg' alt={`slide_image_${index}`} />
                <div className='px-1 md:px-2 py-2'>
                  <div>
                    <span className='uppercase text-xs text-blue-900'>{CategoryVal}</span>
                  </div>
                  <p className='font-medium text-xs md:text-sm'>{product.item_name}</p>
                  <p className='font-medium text-xs md:text-sm price-tag text-red-500'>{formatPrice(product.sell_price)}</p>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
        </div>
      </Swiper>
    </div>
  );
};

export default Batik;
