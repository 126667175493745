import React, { useEffect, useState } from 'react';
import { postData } from '../../data/api';
import { Navigate, useNavigate } from 'react-router-dom';
import { userInfo } from './AuthProvider';

const LoginAcc = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [authLogin, setAuthLogin] = useState({
    username: '',
    password: ''
  });

  const handleInput = (event) => {
      const { name, value } = event.target;
      setAuthLogin({
          ...authLogin,
          [name]: value
      });
  };
  const handlePost = async (event) => {
      event.preventDefault();
      const response = await postData('auth/login', authLogin);
      if (response && response.data.success == true) {
          const { data } = response;
          const expiresInMilliseconds = data.expires_in * 1000;
          const expiresAt = Date.now() + expiresInMilliseconds;
          const readableExpiresAt = new Date(expiresAt).toLocaleString();

          localStorage.setItem('user-info', JSON.stringify({ 
              ...data, 
              expires_in: expiresAt, 
              readable_expires_in: readableExpiresAt 
          }));
          window.location.href = '/';
      } else {
        setAlertMessage('Login failed, verify your username and password');
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 3000);
      }
  };

  if (!userInfo) {
    return (
      <div className='pb-14 text-center'>
        <div className='max-w-[1240px] md:max-w-[640px] mx-auto pt-40 pb-24 px-10 md:px-4 alegreya-sans-regular'>
          <h1 className='font-normal text-2xl md:text-5xl underline underline-offset-[1rem] decoration-2 text-yellow-600'>Login</h1>
          {alertVisible && (
            <div className="relative top-5 w-1/2 bg-red-500 text-white py-1 rounded shadow-md m-auto text-sm">
                {alertMessage}
            </div>
          )}
          <form onSubmit={handlePost}>
            <div class="py-10">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 m-auto">
                <label class="block tracking-wide text-sm mb-2" for="grid-first-name">
                  Email
                </label>
                <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                onChange={handleInput} value={authLogin.username} id="username" name='username' type="text" placeholder=""/>
              </div>
              <div class="w-full md:w-1/2 px-3 m-auto">
                <label class="block tracking-wide text-sm mb-2" for="grid-last-name">
                  Password
                </label>
                <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                onChange={handleInput} value={authLogin.password} id="password" name='password' type="password" placeholder=""/>
                
              </div>
            </div>
            <button onClick={handlePost} className='rounded-full bg-yellow-600 border-0 shadow-[0_10px_20px_-12px] shadow-yellow-600 text-white px-4 py-2 uppercase hover:scale-105 duration-300'>Sign In</button>
          </form>
        </div>
      </div>
    );
  }else{
    return <Navigate to="/" />;
  }
};

const RegistAcc = () => {
  
  return (
    <div className='pb-14'>
      <div className='max-w-[1240px] md:max-w-[640px] mx-auto py-24 px-10 md:px-4 alegreya-sans-regular'>
        <h1 className='font-normal md:text-5xl'>Register Your Account</h1>
        <form action="">
          <div class="md:flex flex-wrap -mx-3 py-10">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block tracking-wide text-sm mb-2" for="grid-first-name">
                First Name
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="text" placeholder=""/>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block tracking-wide text-sm mb-2" for="grid-first-name">
                Last Name
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="text" placeholder=""/>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block tracking-wide text-sm mb-2" for="grid-first-name">
                Email
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="text" placeholder=""/>
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block tracking-wide text-sm mb-2" for="grid-last-name">
                Password
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="password" type="password" placeholder=""/>
            </div>
          </div>
          <button>Submit</button>
          <div class="w-full md:w-1/2 mb-6 md:mb-0">
            <a class="align-baseline text-sm" href="/">
              Cancel
            </a>
          </div>
        </form>
      </div>
      
    </div>
  );
};

const RecoverAcc = () => {

  return (
    <div className='pb-14'>
      <div className='max-w-[1240px] md:max-w-[640px] mx-auto py-24 px-10 md:px-4 alegreya-sans-regular'>
        <h1 className='font-normal md:text-5xl'>Recover Password</h1>
        <p className='font-light pt-10'>
          Please enter your email and we will send you a password reset link.
        </p>
        <form action="">
          <div class="md:flex flex-wrap -mx-3 py-10">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block tracking-wide text-sm mb-2" for="grid-first-name">
                Email
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="text" placeholder=""/>
            </div>
            
          </div>
          <button>Reset Password</button>
          <div class="w-full md:w-1/2 mb-6 md:mb-0">
            <a class="align-baseline text-sm" href="/">
              Cancel
            </a>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export {LoginAcc, RegistAcc, RecoverAcc};
