import { useState, useEffect } from "react";
import { Slider } from "../../components/product/Slider";
import { DetailsSection } from "../../components/product/DetailsSection";
import { useParams } from 'react-router-dom';
import { apiFunctions, fetchProductById } from '../../data/api';
import { RelateProduct } from "../../components/Swiper";

function Index() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [combinedImages, setCombinedImages] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  useEffect(() => {
    fetchProductById(id, (data) => {
      setProduct(data);
      if (data.product_skus && data.product_skus.length > 0) {
        const images = data.product_skus.flatMap((variant) => variant.images.map(image => ({ ...image, variantId: variant.item_id })));
        setCombinedImages(images);
        setSelectedVariant(data.product_skus[0]);
        setActiveImageIndex(images.findIndex(image => image.variantId === data.product_skus[0].item_id));
      }
      const catId = data.item_category_id
      setCategoryId(catId);

      apiFunctions.category({},(data) => {
        const catName = data.filter(item => item.category_id === catId).map(item=>item.category_name)
        setCategoryName(catName);
      });
    });
  }, [id]);

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
    const newIndex = combinedImages.findIndex(image => image.variantId === variant.item_id);
    setActiveImageIndex(newIndex);
  };

  const handleImageChange = (index) => {
    setActiveImageIndex(index);
    const image = combinedImages[index];
    const variant = product.product_skus.find(v => v.item_id === image.variantId);
    setSelectedVariant(variant);
  };

  return (
    <div className="mx-auto pb-20 md:pb-36 border-b-[1px]">
      {product ? (
      <>
      <div className="flex flex-col lg:flex-row md:px-0 md:gap-6 md:py-20 items-center md:justify-center lg:px-14 lg:gap-16">
        <Slider
          images={combinedImages}
          activeImageIndex={activeImageIndex}
          onImageChange={handleImageChange}
        />
        <DetailsSection
          product={product}
          selectedVariant={selectedVariant}
          onVariantChange={handleVariantChange}
          categoryName={categoryName}
        />
      </div>
      <div className="container pt-12 md:pt-0 max-w-[22rem] md:max-w-screen-sm lg:max-w-[900px] xl:max-w-[1040px] m-auto">
        <h1 className="font-bold text-3xl mb-4 md:text-4xl md:mb-5">Related Products</h1>
        {categoryId && categoryName ?
          <RelateProduct category_id={categoryId} CategoryVal={categoryName} /> :
          <div className='m-auto mt-10 text-center'>
            Please wait, the product will appear shortly.
          </div> 
        }
      </div>
      </>
      ) :
      <div className='m-auto py-80 text-center'>
        Please wait, the product will appear shortly.
      </div> 
      }

    </div>
  );
}

export default Index;
