import React, { useState } from "react";

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="alegreya-sans-regular">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={accordionOpen ? 'accordion-btn flex justify-between w-full bg-white text-black hover:text-black hover:bg-transparent m-auto border-x-0 border-b-0' : 'accordion-btn flex justify-between w-full bg-white text-black hover:text-black hover:bg-transparent m-auto border-x-0 border-t-0'}
      >
        <span className="font-semibold">{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        <svg
          className="shrink-0 ml-8 my-auto"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
            <path
              d="M14 7l-6 5.25L2 7"
              className={`transform origin-center transition duration-200 ease-out ${
                accordionOpen ? "" : "-rotate-90"
              }`}
            />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100 m-auto border-b-[1px]"
            : "grid-rows-[0fr] opacity-0 m-auto"
        }`}
      >
        <div className="overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;