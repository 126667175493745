import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { formatPrice } from '../../data/function';

const PaymentOrder = () => {
  const location = useLocation();
  const isSuccess = location.pathname === '/payment-success';

  const dataSuccess = location.state?.dataSuccess;
  React.useEffect(() => {
    if (!dataSuccess) {
      // Handle case where no data is found
      console.log('No payment success data found');
    }
  }, [dataSuccess]);

  if (!dataSuccess) {
    return <div>No payment success data found</div>;
  }

  return (
    <section className="relative h-screen">
      <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        {isSuccess ? (
          <>
            <FaCheckCircle className="m-auto text-9xl text-green-500" />
            <h2 className="font-manrope font-bold text-4xl leading-10 text-black text-center mt-4">
              Payment Successful
            </h2>
            {dataSuccess &&
            <div className='text-center text-lg py-2'>
              <p>Status: <span id="totalPrice" className='text-green-500 font-medium'>Lunas</span></p>
              <p>Order ID: #<span id="orderID" className='font-medium'>{dataSuccess.orderId}</span></p>
              <p>Total Price: <span id="totalPrice" className='font-medium'>{formatPrice(dataSuccess.totalPrice)}</span></p>
            </div>
            }
            <p className="mt-4 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
              Thanks for making a purchase
            </p>
          </>
        ) : (
          <>
            <FaTimesCircle className="m-auto text-9xl text-red-500" />
            <h2 className="font-manrope font-bold text-4xl leading-10 text-black text-center mt-4">
              Payment Failed
            </h2>
            <p className="mt-4 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
              Sorry, your payment was not successful. Please try again.
            </p>
          </>
        )}
        <div className='text-center'>
          <a href="/" className='border-[1px] border-yellow-600 rounded p-2 text-yellow-600 hover:bg-yellow-600 hover:text-white'>Back to Home</a>
        </div>
      </div>
    </section>
  );
};

export default PaymentOrder;
