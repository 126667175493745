import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ProductPage from './ProductPage'

function Products() {
  return (
    <div>
        <ProductPage />
    </div>
  );
}

export default Products;
