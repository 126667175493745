import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { fetchProvinces, fetchCities, fetchSubdistricts, fetchCourier, postData, apiFunctions } from '../../data/api';
import { formatPrice } from '../../data/function';
import { userInfo } from '../Account/AuthProvider';
import Modal from '../../components/commons/Modal';
import { FaArrowRightLong, FaCircleArrowLeft } from 'react-icons/fa6';
import qris from '../../assets/qris.png'
import { useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [modalPurchase, setModalPurchase] = useState(false);
  const [SelectPaymentMethod, setPaymentMethod] = useState('adminPaymentMethod');
  const paymentMethod = userInfo ? SelectPaymentMethod : 'midtrans';

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [subdistricts, setSubdistricts] = useState([]);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(null);
  const [courier, setCourier] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [ExpedName, setExpedName] = useState([]);
  const [ExpedCost, setExpedCost] = useState([]);
  const [selectedExped, setSelectedExped] = useState(null);

  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const [voucherCode, setVoucherCode] = useState('');
  const [resultVocher, setVocher] = useState(null);
  const [alertVoucher, setAlertVoucher] = useState(false);
  const [msgVocher, setVocherMsg] = useState(null);

  const handleBack = () => {
    setPaymentMethod('adminPaymentMethod');
  };
  const handleCancel = ()=>{
    navigate('/');
  }

  const handleVoucherChange = (e) => {
    setVoucherCode(e.target.value);
  };
  const handleCheckVoucher = () => {
    apiFunctions.voucher({ code: voucherCode, payment: totalPrice }, (data) => {
      if(data.status === true){
        setVocher(data.data);
        setVocherMsg(data.message);
        // setAlertVoucher(true);
      }else{
        setVocherMsg(data.message);
        setAlertVoucher(true);
        setTimeout(() => setAlertVoucher(false), 3000);
        setVocher(null);
      }
    });
  };

  const [shippingDetails, setShippingDetails] = useState({
    shipping_full_name: '',
    shipping_email: '',
    shipping_phone: '',
    shipping_note: '',
    shipping_post_code: '',
    shipping_country: 'Indonesia',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (selectedCity && selectedCity.postalCode) {
      setShippingDetails((prevDetails) => ({
        ...prevDetails,
        shipping_post_code: selectedCity.postalCode,
      }));
    }
  }, [selectedCity]);
  
  useEffect(() => {
    
    const loadCheckout = async () => {
      const data = await fetchProvinces();
      setProvinces(data);
      const dataCourier = await fetchCourier();
      setCourier(dataCourier);

      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        setCartItems(JSON.parse(storedCart));
        const totalPrice = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + (item.quantity * item.sell_price);
        }, 0);
        setTotalPrice(totalPrice);

        const totalweight = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + (parseInt(item.package_weight));
        }, 0);
        setTotalWeight(totalweight);
        const totalheight = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + (parseInt(item.package_height));
        }, 0);
        setTotalHeight(totalheight);
        const totalwidth = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + (parseInt(item.package_width));
        }, 0);
        setTotalWidth(totalwidth);
        const totallength = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + (parseInt(item.package_length));
        }, 0);
        setTotalLength(totallength);
      }
    };
    loadCheckout();
  }, []);

  const handleCourierChange = async (selectedOption) => {
    setSelectedCourier(selectedOption);

    if (selectedSubdistrict) {
      const endpoint = 'ekpedisi/cost';
      const data = {
        weight: totalWeight,
        height: totalHeight,
        width: totalWidth,
        length: totalLength,
        courier: selectedOption.value,
        destination: selectedSubdistrict.value
      };

      try {
        const response = await postData(endpoint, data);
        setExpedName(response.data.rajaongkir.results[0].name)
        setExpedCost(response.data.rajaongkir.results[0].costs)
      } catch (error) {
        console.error('Error posting shipping details:', error);
      }
    }
  };
  
  const handleProvinceChange = async (selectedOption) => {
    setSelectedProvince(selectedOption);
    setSelectedCity(null)
    setSelectedSubdistrict(null)
    const citiesData = await fetchCities(selectedOption.value);
    setCities(citiesData);
  };

  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    setSelectedSubdistrict(null)
    const subdistrictsData = await fetchSubdistricts(selectedOption.value);
    setSubdistricts(subdistrictsData);
  };

  const handleSubsChange = async (selectedOption) => {
    setSelectedSubdistrict(selectedOption);
    setSelectedCourier(null);
  };

  const handleExpedChange = async (selectedOption) => {
    setSelectedExped(selectedOption);
  };

  const handlePayment = () =>{
    setPaymentMethod('adminPaymentMethod')
    setModalPurchase(true)
  }

  const handleCompletePurchase = async () => {
    if(!userInfo){
      if (!shippingDetails.shipping_full_name || 
        !shippingDetails.shipping_email || 
        !shippingDetails.shipping_phone || 
        !selectedProvince || 
        !selectedCity || 
        !selectedSubdistrict || 
        !selectedCourier || 
        !selectedExped) {
        setAlertMessage('All fields are required!');
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 3000);
        return;
      }
    } 

    // Construct items array from cartItems
    const items = cartItems.map(item => ({
      item_id: item.item_id, 
      serial_no: item.item_code,
      description: item.item_group_name+' - '+ item.variant, 
      tax_id: 1, 
      price: item.sell_price, 
      qty: item.quantity, 
      disc: 0, 
      disc_amount: 0, 
      tax_amount: 0, 
    }));
  
    const payload = {
      customer_name: shippingDetails.shipping_full_name,
      note: shippingDetails.shipping_note +' - '+ (selectedExped ? selectedExped.label : ''),
      source: 1,
      shipping_cost: selectedExped ? selectedExped.value : 0,
      is_paid: false,
      shipping_full_name: shippingDetails.shipping_full_name,
      shipping_phone: shippingDetails.shipping_phone,
      shipping_address: !userInfo ? selectedSubdistrict.label+', '+selectedCity.label+', '+selectedProvince.label : '-',
      shipping_post_code: shippingDetails.shipping_post_code,
      shipping_country: shippingDetails.shipping_country,
      total_disc: resultVocher ? (resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount) : 0,
      add_fee: 0,
      email: shippingDetails.shipping_email,
      payment_method: paymentMethod,

      items,
    };
  
    try {
      let response;
      // Send POST request to create order
      if (!userInfo) {
        response = await postData('order/createOrder', payload);
        if (response.data && response.data.data['midtrans-token']) {
          const token = response.data.data['midtrans-token']
    
          window.snap.pay(token, {
            onSuccess: async function (result) {
              const orderSuccessResponse = await postData('order/successOrder',{
                token: token,
              })
              const dataSuccess = {
                orderId: orderSuccessResponse.data.data.order_id,
                totalPrice: selectedExped && selectedExped !== null ? formatPrice(selectedExped.value+totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0)) : formatPrice(totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0))
              }
              localStorage.setItem('cart', JSON.stringify([]));
              navigate('/payment-success', { state: { dataSuccess } });
              window.location.href = `/payment-success`;
            },
            onPending: function (result) {
              console.log('Payment pending', result);
            },
            onError: function (result) {
              console.error('Payment error', result);
              window.location.href = '/payment-failed';
            },
            onClose: function () {
              console.log('Popup closed without completing payment');
            }
          });
        } else {
          console.error('No Midtrans token found in response');
        }
      } else {
        const headers = {
          Authorization: `Bearer ${userInfo.access_token}`, 
        };
        response = await postData('store/order/createOrder', payload, headers);
        if(response.data && response.data.status === 200){
          const dataSuccess = response.data.data
          localStorage.setItem('cart', JSON.stringify([]));
          navigate('/payment-success', { state: { dataSuccess } });
          window.location.href = '/payment-success';
        }else{
          console.error('Checkout Failed');
          window.location.href = '/payment-failed';
        }
      }
      
      
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  return (
    <div class="font-[sans-serif] bg-gray-50 pt-16">
      <form>
      <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 h-full">
        <div class="xl:col-span-2 h-max rounded-md p-8 pt-14 relative top-0">
          <h2 class="text-2xl font-bold text-[#333]">Complete your order</h2>
            <div class="mt-10">
              <h3 class="text-lg font-bold text-[#333] mb-6">Personal Details</h3>
              <div class="grid gap-6 mb-6">
                <div class="relative flex items-center">
                  <input type="text" placeholder="Full Name" name="shipping_full_name"
                    value={shippingDetails.shipping_full_name}
                    onChange={handleInputChange}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-yellow-700 outline-none" 
                    />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 24 24">
                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"></path>
                  </svg>
                </div>
              </div>
              <div class="grid sm:grid-cols-2 gap-6">
                <div class="relative flex items-center">
                  <input type="email" placeholder="Email" name="shipping_email"
                    value={shippingDetails.shipping_email}
                    onChange={handleInputChange}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-yellow-700 outline-none" />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 682.667 682.667">
                    <defs>
                      <clipPath id="a" clipPathUnits="userSpaceOnUse">
                        <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#a)" transform="matrix(1.33 0 0 -1.33 0 682.667)">
                      <path fill="none" stroke-miterlimit="10" stroke-width="40"
                        d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                        data-original="#000000"></path>
                      <path
                        d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                        data-original="#000000"></path>
                    </g>
                  </svg>
                </div>
                <div class="relative flex items-center">
                  <input type="text" placeholder="Phone No." name="shipping_phone"
                    value={shippingDetails.shipping_phone}
                    onChange={handleInputChange}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-yellow-700 outline-none" />
                  <svg fill="#bbb" class="w-[18px] h-[18px] absolute right-4" viewBox="0 0 64 64">
                    <path
                      d="m52.148 42.678-6.479-4.527a5 5 0 0 0-6.963 1.238l-1.504 2.156c-2.52-1.69-5.333-4.05-8.014-6.732-2.68-2.68-5.04-5.493-6.73-8.013l2.154-1.504a4.96 4.96 0 0 0 2.064-3.225 4.98 4.98 0 0 0-.826-3.739l-4.525-6.478C20.378 10.5 18.85 9.69 17.24 9.69a4.69 4.69 0 0 0-1.628.291 8.97 8.97 0 0 0-1.685.828l-.895.63a6.782 6.782 0 0 0-.63.563c-1.092 1.09-1.866 2.472-2.303 4.104-1.865 6.99 2.754 17.561 11.495 26.301 7.34 7.34 16.157 11.9 23.011 11.9 1.175 0 2.281-.136 3.29-.406 1.633-.436 3.014-1.21 4.105-2.302.199-.199.388-.407.591-.67l.63-.899a9.007 9.007 0 0 0 .798-1.64c.763-2.06-.007-4.41-1.871-5.713z"
                      data-original="#000000"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <h3 class="text-lg font-bold text-[#333] mb-6">Shipping Address</h3>
              <div class="grid sm:grid-cols-2 gap-6">
                <Select
                  options={provinces.map((province) => ({ value: province.province_id, label: province.province }))}
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  placeholder="Select Province"
                />
                <Select
                  options={cities.map((city) => ({ value: city.city_id, label: city.city_name+' ('+city.type+')',postalCode: city.postal_code }))}
                  // options={cities !== null ? cities.map((city) => ({ value: city.city_id, label: city.city_name+' ('+city.type+')',postalCode: city.postal_code })) : {label: 'select provience first'}}
                  value={selectedCity}
                  onChange={handleCityChange}
                  placeholder="Select City"
                />
                <Select
                  options={subdistricts.map((subdistrict) => ({ value: subdistrict.subdistrict_id, label: subdistrict.subdistrict_name }))}
                  value={selectedSubdistrict}
                  onChange={handleSubsChange}
                  placeholder="Select Subdistrict"
                />
                <Select className='courier-select'
                  options={courier.map((data) => ({ value: data, label: data }))}
                  value={selectedCourier}
                  onChange={handleCourierChange}
                  placeholder="Select Courier"
                />
                
              </div>
              {selectedCourier ?
              <div className='mt-6'>
                <Select
                    options={ExpedCost.map((data) => ({ value: data.cost[0].value, label: ExpedName+ ' - ' +data.service }))}
                    value={selectedExped}
                    onChange={handleExpedChange}
                    placeholder="Select Expedition"
                /> 
              </div>
              : ''
              }
              <div className='mt-6'>
                <textarea type="text" rows="4" placeholder="Note" name="shipping_note"
                  value={shippingDetails.shipping_note}
                  onChange={handleInputChange}
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-yellow-700 outline-none" />
              </div>
              {/* <div class="flex gap-6 max-sm:flex-col mt-10">
                <button type="button" class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-transparent hover:bg-gray-100 border-2 border-yellow-600 text-yellow-700">Cancel</button>
                <button type="button" class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-yellow-600 border-yellow-600 text-white hover:bg-yellow-700"
                  onClick={userInfo ? (handlePayment) : (handleCompletePurchase)}>
                  Complete Purchase
                </button>
              </div> */}
            </div>
          
        </div>
        <div class="bg-yellow-700 lg:h-screen lg:sticky lg:top-0">
          <div class="relative h-full">
            <div class="p-4 md:p-8 md:pt-12 pb-[12rem] lg:pb-[10rem] h-screen">
              <h2 class="text-2xl font-bold text-white">Order Summary</h2>
              <div class="space-y-6 mt-4 md:mt-8 lg:mt-10 overflow-auto h-96 md:h-[24rem] lg:h-[25.5rem]">
                {cartItems.map(item => (
                <div class="grid grid-cols-2 items-start gap-1">
                  <div class="w-40 h-40 md:w-44 md:h-44 p-2 shrink-0 bg-gray-50 rounded-md">
                    <img src={item.thumbnail} class="w-full h-full object-cover m-auto" />
                  </div>
                  <div>
                    <h3 class="text-sm md:text-base font-semibold text-white">{item.item_group_name}</h3>
                    <ul class="text-xs text-white space-y-3 mt-2">
                      <li class="flex flex-wrap gap-4">Variant <span class="ml-auto">{item.variant}</span></li>
                      <li class="flex flex-wrap gap-4">Quantity <span class="ml-auto">{item.quantity}</span></li>
                      <li class="flex flex-wrap gap-4">Unit Price <span class="ml-auto">{formatPrice(item.sell_price)}</span></li>
                      <li class="flex flex-wrap gap-4">Total Price <span class="ml-auto">{formatPrice(item.sell_price*item.quantity)}</span></li>
                    </ul>
                  </div>
                </div>
                ))}
              </div>
            </div>
            <div class="absolute left-0 bottom-0 bg-yellow-600 w-full p-4">
              <div className='flex flex-wrap gap-4 text-base text-white mb-2'>
                <h4 className='my-auto'>Voucher</h4>
                <div className='flex relative items-center ml-auto'>
                  <input type="text" placeholder="Enter Code" name="voucher_code"
                    value={voucherCode}
                    onChange={handleVoucherChange}
                    class="pl-3 pr-4 mr-6 py-3 bg-white text-[#333] w-full text-sm outline-none rounded-md" />
                  <div class="bg-yellow-600 hover:bg-yellow-700 absolute right-0 border-white border-[1px] rounded-r-md h-full cursor-pointer"
                    onClick={handleCheckVoucher}>
                    <p className='text-white p-3 text-sm'>Check</p>
                  </div>
                </div>
              </div>
              {alertVoucher && (
                <div className='flex'>
                  <div className="relative bottom-1 w-auto ml-auto bg-red-500 text-xs text-white py-1 px-2 text-center rounded shadow-md">
                      {msgVocher}
                  </div>
                </div>
              )}
              <h4 class="flex flex-wrap gap-4 text-base text-white mb-2">SubTotal <span class="ml-auto">{formatPrice(totalPrice)}</span></h4>
              {resultVocher &&
                <h4 class="flex flex-wrap gap-4 text-base text-white mb-2">Diskon <span class="ml-auto">{resultVocher.is_percentage === true ? resultVocher.discount+'%' : '- '+formatPrice(resultVocher.discount)}</span></h4>
              }
              <h4 class="flex flex-wrap gap-4 text-base text-white mb-2">Shipping <span class={`ml-auto ${selectedExped ? '' : 'text-xs my-auto ' }`}>{selectedExped && selectedExped !== null ? formatPrice(selectedExped.value) : 'Fill the Shipping Address'}</span></h4>
              <h4 class="flex flex-wrap gap-4 text-base text-white">Total <span class="ml-auto">{selectedExped && selectedExped !== null ? formatPrice(selectedExped.value+totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0)) : formatPrice(totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0))}</span></h4>
            </div>
          </div>
        </div>
        <div class="flex gap-6 w-full max-sm:flex-col mt-10 lg:mt-0 w-11/12 xl:w-full mx-auto relative left-0 xl:left-[50%] bottom-[30%] lg:bottom-[50%]">
          <button type="button" class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-transparent hover:bg-gray-100 border-2 border-yellow-600 text-yellow-700"
                  onClick={handleCancel}>
                  Cancel
          </button>
          <button type="button" class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-yellow-600 border-yellow-600 text-white hover:bg-yellow-700"
            onClick={userInfo ? (handlePayment) : (handleCompletePurchase)}>
            Complete Purchase
          </button>
        </div>
      </div>
      </form>
      {alertVisible && (
        <div className="fixed top-20 left-4 bg-red-500 text-white py-2 px-4 rounded shadow-md">
            {alertMessage}
        </div>
      )}
      <Modal open={modalPurchase} onClose={() => setModalPurchase(false)}>
        {/* {response && ( */}
          <div className="text-center w-72 md:w-96 pt-4 pb-10">
            {SelectPaymentMethod === 'adminPaymentMethod' && (
              <>
                <div>
                  {/* <p className='text-sm text-gray-400 text-justify underline underline-offset-2 pb-4'>Order Id: {response.data.orderId}</p> */}
                  <p className="text-xl">Total Payment</p>
                  <p className="font-semibold text-xl">{selectedExped && selectedExped !== null ? formatPrice(selectedExped.value+totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0)) : formatPrice(totalPrice-(resultVocher?(resultVocher.is_percentage === true ? totalPrice*resultVocher.discount/100 : resultVocher.discount):0))}</p>
                </div>
                <div>
                  <p className="text-gray-400 pt-4 pb-2 text-justify">Payment Method:</p>
                  <div className="text-left text-lg">
                    <div className="flex justify-between border-b-[1px] w-full p-2" onClick={() => setPaymentMethod('qris')}>
                      <p>Qris</p>
                      <FaArrowRightLong className="my-auto" />
                    </div>
                    <div className="flex justify-between border-b-[1px] w-full p-2" onClick={() => setPaymentMethod('bank')}>
                      <p>Bank Transfer</p>
                      <FaArrowRightLong className="my-auto" />
                    </div>
                    <div className="flex justify-between border-b-[1px] w-full p-2" onClick={() => setPaymentMethod('cash')}>
                      <p>Cash</p>
                      <FaArrowRightLong className="my-auto" />
                    </div>
                  </div>
                </div>
              </>
            )}
            {SelectPaymentMethod === 'qris' && (
              <div>
                <div onClick={handleBack} className="text-yellow-600 border-0 text-2xl"><FaCircleArrowLeft/></div>
                <p className="text-xl font-semibold">Qris Payment</p>
                <p>Scan Here</p>
                <img src={qris} alt="Qris QR Code" className='my-3'/>
                <button onClick={handleCompletePurchase} className='rounded-full bg-yellow-600 border-0 shadow-[0_10px_20px_-12px] shadow-yellow-600 text-white px-4 py-2 uppercase hover:scale-105 duration-300'>Sudah Bayar</button>
              </div>
            )}
            {SelectPaymentMethod === 'bank' && (
              <div>
                <div onClick={handleBack} className="text-yellow-600 border-0 text-2xl"><FaCircleArrowLeft/></div>
                <p className="text-xl font-semibold">Bank Transfer Payment</p>
                <p className='p-2'>Name: ANA KHAIRANI</p>
                <div className='pb-4 text-justify w-72 m-auto'>
                  <p className='p-2'>Bank BCA: 2551-266-046</p>
                  <p className='p-2'>Bank BRI: 122-401-013-536-504</p>
                  <p className='p-2'>Bank MANDIRI: 133-001-4833-909</p>
                  <p className='p-2'>Bank BNI: 0710-526-978</p>
                  <p className='p-2'>Bank Maybank: 1-736-00120-3</p>
                  <p className='p-2'>Bank BSI: 2002000288</p>
                </div>
                <button onClick={handleCompletePurchase} className='rounded-full bg-yellow-600 border-0 shadow-[0_10px_20px_-12px] shadow-yellow-600 text-white px-4 py-2 uppercase hover:scale-105 duration-300'>Sudah Bayar</button>
              </div>
            )}
            {SelectPaymentMethod === 'cash' && (
              <div>
                <div onClick={handleBack} className="text-yellow-600 border-0 text-2xl"><FaCircleArrowLeft/></div>
                <p className="text-xl font-semibold">Cash Payment</p>
                <div className='py-4'>
                  <p className='p-2'>Pay with cash at our store location.</p>
                </div>
                <button onClick={handleCompletePurchase} className='rounded-full bg-yellow-600 border-0 shadow-[0_10px_20px_-12px] shadow-yellow-600 text-white px-4 py-2 uppercase hover:scale-105 duration-300'>Sudah Bayar</button>
              </div>
            )}
          </div>
        {/* )} */}
      </Modal>

    </div>
  );
};

export default CheckoutPage;
