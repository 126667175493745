import React, { useState, useEffect } from 'react';

export const CTASection = ({ ProductsVal, selectedVariant }) => {
    const [productCounter, setProductCounter] = useState(1);
    const [success, setAlertSuccess] = useState(null);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [maxQuantityReached, setMaxQuantityReached] = useState(false);

    useEffect(() => {
        const handleCartUpdate = () => {
            const storedCart = localStorage.getItem('cart');
            const cartItems = storedCart ? JSON.parse(storedCart) : [];
            const existingItem = cartItems.find(item => item.item_id === selectedVariant.item_id);
            if (existingItem) {
                const remainingStock = selectedVariant.end_qty - existingItem.quantity;
                if (remainingStock <= 0) {
                    setMaxQuantityReached(true);
                    setProductCounter(0);
                } else {
                    setMaxQuantityReached(false);
                    setProductCounter(1); // Reset to minimum allowable quantity
                }
            } else {
                setMaxQuantityReached(false);
                setProductCounter(1); // Reset to minimum allowable quantity
            }
        };

        window.addEventListener('cartUpdated', handleCartUpdate);
        return () => {
            window.removeEventListener('cartUpdated', handleCartUpdate);
        };
    }, [selectedVariant]);

    const addProduct = () => {
        if (selectedVariant && productCounter < selectedVariant.end_qty) {
            setProductCounter(prevCount => prevCount + 1);
        } else {
            setAlertMessage('Out of stock!');
            setAlertSuccess(null);
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 3000);
        }
    };

    const removeProduct = () => setProductCounter(prevCount => (prevCount > 1 ? prevCount - 1 : 1));

    const addToCart = (ProductsVal, selectedVariant) => {
        if (!selectedVariant || productCounter <= 0) {
            console.error('Invalid variant or quantity');
            return;
        }

        const { item_group_name, package_weight, package_height, package_width, package_length } = ProductsVal;
        const { item_id, sell_price: initialSellPrice, images, variation_values, item_code, end_qty, discounted_price } = selectedVariant;
        const thumbnail = images && images[0] ? images[0].thumbnail : '/path/to/default/image.jpg';
        const variant = variation_values && variation_values[0] ? variation_values[0].value : '-';
        let sell_price = discounted_price ? discounted_price : initialSellPrice;

        const storedCart = localStorage.getItem('cart');
        let cartItems = storedCart ? JSON.parse(storedCart) : [];

        const existingItemIndex = cartItems.findIndex(item => item.item_id === item_id);

        if (existingItemIndex !== -1) {
            const newQuantity = cartItems[existingItemIndex].quantity + productCounter;
            if (newQuantity > selectedVariant.end_qty) {
                setAlertMessage('Out of stock!');
                setAlertSuccess(null);
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 3000);
                return;
            }
            cartItems[existingItemIndex].quantity = newQuantity;
        } else {
            if (productCounter > selectedVariant.end_qty) {
                setAlertMessage('Out of stock!');
                setAlertSuccess(null);
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 3000);
                return;
            }
            cartItems.push({
                item_id,
                item_group_name,
                sell_price,
                variant,
                quantity: productCounter,
                thumbnail,
                package_weight,
                package_height,
                package_width,
                package_length,
                item_code,
                end_qty
            });
        }

        localStorage.setItem('cart', JSON.stringify(cartItems));

        const cartUpdateEvent = new Event('cartUpdated');
        window.dispatchEvent(cartUpdateEvent);

        setAlertMessage('Successfully added to cart!');
        setAlertSuccess(1);
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 3000);

        setProductCounter(1);
    };

    return (
        <div className='flex flex-col justify-center items-center gap-4 md:flex-row'>
            <div className='flex w-full bg-[#f7f8fe] justify-between items-center px-6 py-2 rounded-xl mt-2
            md:w-1/3 md:px-3 md:mt-0'>
                <div>
                    <button
                        onClick={removeProduct}
                        className='font-bold text-yellow-600 text-2xl pb-1 border-0 '
                    >
                        -
                    </button>
                </div>

                <div className='font-bold'>
                    {productCounter}
                </div>

                <div>
                    <button
                        onClick={addProduct}
                        className='font-bold text-yellow-600 text-2xl pb-1 border-0 '
                    >
                        +
                    </button>
                </div>
            </div>
            {alertVisible && (
                <div className={`fixed top-20 right-4 text-white py-2 px-4 rounded shadow-md z-[100] ${success ? 'bg-green-500' : 'bg-red-500'}`}>
                    {alertMessage}
                </div>
            )}
            <button
                onClick={() => addToCart(ProductsVal, selectedVariant)}
                className='w-full bg-yellow-600 text-white py-4 rounded-xl font-bold border-0  
                shadow-[0_10px_30px_-12px] shadow-yellow-600 md:w-2/3'
            >
                <div className='flex gap-4 justify-center'>
                    <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M20.925 3.641H3.863L3.61.816A.896.896 0 0 0 2.717 0H.897a.896.896 0 1 0 0 1.792h1l1.031 11.483c.073.828.52 1.726 1.291 2.336C2.83 17.385 4.099 20 6.359 20c1.875 0 3.197-1.87 2.554-3.642h4.905c-.642 1.77.677 3.642 2.555 3.642a2.72 2.72 0 0 0 2.717-2.717 2.72 2.72 0 0 0-2.717-2.717H6.365c-.681 0-1.274-.41-1.53-1.009l14.321-.842a.896.896 0 0 0 .817-.677l1.821-7.283a.897.897 0 0 0-.87-1.114ZM6.358 18.208a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm10.015 0a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm2.021-7.243-13.8.81-.57-6.341h15.753l-1.383 5.53Z" fill="currentColor" fillRule="nonzero"/></svg>
                    Add to cart
                </div>
            </button>
        </div>
    );
};
