import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export const Slider = ({ images, activeImageIndex, onImageChange }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    if (thumbsSwiper) {
      thumbsSwiper.slideTo(activeImageIndex);
    }
  }, [activeImageIndex, thumbsSwiper]);

  return (
    <div className='relative md:w-full max-w-[23rem] md:max-w-[600px] lg:max-w-[500px] mt-20 md:mt-6 mb-auto'>
      <div id="swiper-product" className="w-full h-full p-2">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[FreeMode, Navigation, Thumbs]}
          onSlideChange={(swiper) => onImageChange(swiper.realIndex)}
        >
          {images.length > 0 && images.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="w-auto h-full rounded-xl bg-cover">
                <img src={item.cloud_key || '/path/to/default/image.jpg'} alt="" className="object-cover w-full h-full rounded-xl" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="py-4">
          <Swiper
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress
            modules={[FreeMode, Thumbs]}
            onSwiper={setThumbsSwiper}
          >
            {images.length > 0 && images.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="w-auto rounded-xl">
                  <img src={item.cloud_key || '/path/to/default/image.jpg'} alt="" className="object-cover w-full h-full rounded md:rounded-xl" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
