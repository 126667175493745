import React, { useEffect, useState }  from 'react';
import {Box,Fab,Link} from '@mui/material';
import { FaWhatsappSquare } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { apiFunctionsLP } from '../data/api';



export default function FloatingActionButtons() {
  const [contactVal, setContact] = useState([]);

  useEffect(()=>{
    apiFunctionsLP.contact((data) => {
      // console.log(data.data,'contactLP');
      setContact(data.data);
    });
  }, [])
  return (
    <div className='fixed z-10 bottom-16 lg:bottom-4 right-1 lg:right-2'>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Link href={contactVal.whatsapp} target="_blank" rel="noopener">
                <Fab variant="extended" class='alegreya-sans-regular bg-white rounded text-black hover:bg-[#f5f5f5] px-2 py-2 border-[#cccccc]'>
                    <div className='flex'>
                    <RiWhatsappFill className="h-8 w-8 mr-1 text-green-500"/>
                    {/* <WhatsAppIcon color="success"  sx={{ mr: 1 }} /> */}
                    <p className='my-auto'>Need help?</p>
                    </div>
                </Fab>
            </Link>
        </Box>
    </div>
  );
}