export const formatPrice = (price) => {
  const numericPrice = parseFloat(price);
  if (!isNaN(numericPrice)) {
    const formattedPrice = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numericPrice);
    return formattedPrice;
  }
  return price;
};
  